<template>
    <b-modal :ref="modalRef" hide-footer @hidden="onHidden" title="Fin d'année" centered no-close-on-backdrop>
        <height-fade-transition>
            <div v-if="step === 1">
                <b-progress :value="initialProgress" :max="initialProgressMax"
                            variant="info" striped animated height="1rem"/>
                <p>
                    Pendant que nous préparons votre outil d'extermination de l'humanité, veuillez activer le son de
                    votre ordinateur pour apprécier l'apocalypse dans des conditions optimales
                </p>
            </div>
        </height-fade-transition>
        <height-fade-transition>
            <div v-if="step === 2">
                <p>
                    Maintenant que tu as attendu 30 secondes uniquement pour que la musique arrive à un moment
                    intéressant (non il ne s'est rien passé d'autre pendant ces 30 secondes), voilà ce qu'il va
                    se passer si tu continues : Tous les comptes de Clujistes seront désactivés (sauf les membres du
                    bureau évidemment, réfléchis 2 minutes Gérard), ce qui veut dire qu'ils devront demander à ce que
                    leur compte soit réactivé s'ils se réinscrivent l'année prochaine, on fait ça ?
                </p>
                <div class="text-center">
                    <b-button variant="success" @click="step++">
                        Yes
                        <font-awesome-icon icon="check"/>
                    </b-button>
                </div>
            </div>
        </height-fade-transition>
        <height-fade-transition>
            <div v-if="step === 3">
                <b-input type="password" v-model="password" placeholder="Mot de passe" autocomplete="current-password"/>
                <p class="mt-2">
                    C'est pas que je ne te fais pas confiance, mais re-connecte toi pour être sûr (on sait jamais)
                </p>
                <div class="text-center">
                    <b-button variant="success" @click="passwordCheck" :disabled="passwordCheckLoading">
                        Vérifier
                        <font-awesome-icon icon="sync" spin v-if="passwordCheckLoading"/>
                    </b-button>
                </div>
            </div>
        </height-fade-transition>
        <height-fade-transition>
            <div v-if="step === 4">
                <b-progress :value="secondProgress" :max="secondProgressMax"
                            :variant="secondProgressVariant" striped animated height="1rem"/>
                <p>
                    Parfait ! Je sais pas ce que t'en penses mais je propose qu'on se refasse une petite barre de
                    progression inutile pour l'occasion, c'est toujours sympatique
                </p>
            </div>
        </height-fade-transition>
        <height-fade-transition>
            <div v-if="step >= 5" class="huge-icon text-center">
                <font-awesome-icon icon="cog" spin/>
            </div>
        </height-fade-transition>
    </b-modal>
</template>

<script>
    import modalMixin     from '@/mixin/modalMixin';
    import {requireAudio} from '@/util/resources';
    import {apiPath}      from '@/util/http';

    const HeightFadeTransition = () => import('@/components/HeightFadeTransition');

    export default {
        name: 'EndYearModal',
        components: {HeightFadeTransition},
        mixins: [modalMixin],
        data: () => ({
            modalRef: 'endYearModal',
            initialProgress: 1,
            initialProgressMax: 320,
            initialProgressInterval: null,
            step: 1,
            music: null,
            secondProgress: 1,
            secondProgressMax: 100,
            secondProgressVariant: 'success',
            secondProgressInterval: null,
            password: null,
            passwordCheckLoading: false
        }),
        methods: {
            onHidden() {
                this.music.pause();
                this.music.removeAttribute('src'); // empty source
                this.music.load();
                this.destroyModal();
            },
            triggerInitialProgress() {
                this.music = new Audio(requireAudio('Victory.mp3'));
                this.music.play();
                this.initialProgressInterval = setInterval(() => {
                    this.initialProgress++;
                }, 100);
            },
            passwordCheck() {
                this.passwordCheckLoading = true;
                this.axios.post(apiPath('verify_password_user'), {password: this.password})
                    .then(() => this.triggerSecondProgress())
                    .catch(() => this.$toaster.error('Mot de passe incorrect'))
                    .finally(() => this.passwordCheckLoading = false);
            },
            triggerSecondProgress() {
                this.step++;
                this.secondProgressInterval = setInterval(() => {
                    let available              = ['success', 'danger', 'info', 'warning'];
                    this.secondProgressVariant = available[Math.floor(Math.random() * available.length)];
                    this.secondProgress += Math.round(Math.random() * 15 - 5);
                }, 1000);
            },
            triggerActualProcessing() {
                this.axios.post(apiPath('webcretaire_end_year'), {password: this.password})
                    .then(this.$router.push({name: 'void'}))
                    .catch(() => this.$toaster.error('Erreur dans le traitement final'))
                    .finally(() => this.hideModal());
            }
        },
        watch: {
            initialProgress(val) {
                this.initialProgress = val;
                if (val === this.initialProgressMax) {
                    clearInterval(this.initialProgressInterval);
                    this.step++;
                }
            },
            secondProgress(val) {
                this.secondProgress = val;
                if (val >= this.secondProgressMax) {
                    clearInterval(this.secondProgressInterval);
                    this.step++;
                    this.triggerActualProcessing();
                }
            }
        },
        mounted() {
            this.triggerInitialProgress();
        }
    };
</script>

<style scoped>
    .huge-icon {
        font-size: 150px;
    }
</style>